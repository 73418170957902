<template>
  <div class="com-box">
    <div class="item">
      <ek-item-info title="消费区间分布" />
    </div>
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.xAxis.data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l2_7 } from '@/api/do'

export default {
  data() {
    return {
      options: {
        color: ['#FFAC4A'],
        title: {
          text: '消费笔数(万次)',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            fontWeight: 500
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${parseFloat(
                            (params.value / 10000).toFixed(2)
                          )}万次</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            interval: 0,
            rotate: 30
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            // 网格线为虚线
            show: false
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 14,
            formatter: function(val) {
              return parseFloat((val / 10000).toFixed(2))
            }
          }
        },
        series: [
          {
            name: '消费笔数',
            type: 'bar',
            data: []
          }
        ]
      },
      empty: false,
      dateObj: null
    }
  },
  mounted() {
    this.$eventBus.$off('do-L2-7')
    this.$eventBus.$on('do-L2-7', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l2_7(params)
      if (resultCode === '200' && resultData) {
        console.log('l2_7', resultData)
        let xAxisData = []
        let seriesData = []
        resultData.map(item => {
          xAxisData.push(item.name)
          seriesData.push(item.num)
        })
        this.options.xAxis.data = xAxisData
        this.options.series[0].data = seriesData

        this.empty = !resultData.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .item {
    margin-bottom: 5px;
  }
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
