<template>
  <div class="com-box">
    <ek-item-info title="性别、年龄分布" />
    <div class="box">
      <img
        class="bg"
        v-if="!empty"
        src="/images/components/do/l1_2.png"
        alt=""
      />
      <ek-chart v-if="!empty" :options="options" />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l1_2and3 } from '@/api/do'

export default {
  data() {
    return {
      empty: false,
      options: {
        color: ['#00FFFF', '#2BFA5C', '#D3FD2A', '#81B3FE', '#FFDC1A'],
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let val =
              params.value > 10000
                ? parseFloat(params.value / 10000).toFixed(2) + '万'
                : params.value
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${val}人</span>
                          <span class="v">&nbsp${params.percent}%</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          bottom: 0,
          icon: 'circle',
          itemWith: 12,
          itemHeight: 12,
          type: 'scroll',
          pageTextStyle: {
            color: '#fff'
          },
          textStyle: {
            color: '#fff',
            fontSize: 14,
            lineHeight: 28
          }
        },
        series: [
          {
            name: '性别',
            type: 'pie',
            selectedMode: 'single',
            center: ['50%', '45%'],
            radius: [0, '20%'],
            label: {
              position: 'inner'
            },
            labelLine: {
              show: false
            },
            data: []
          },
          {
            name: '年龄',
            type: 'pie',
            center: ['50%', '45%'],
            radius: ['35%', '45%'],
            label: {
              color: '#fff',
              formatter: `{d|{d}%}`,
              fontSize: 12,
              rich: {
                d: {
                  color: '#1EFD6D',
                  fontSize: 12
                }
              }
            },
            itemStyle: {
              borderColor: '#050A0F',
              borderWidth: 2
            },
            data: []
          }
        ]
      },
      sexList: [],
      ageList: [],
      dateObj: null
    }
  },
  components: {},
  mounted() {
    this.$eventBus.$off('do-L1-2')
    this.$eventBus.$on('do-L1-2', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      await this.apiFn(l1_2and3, params, 'sex')
      await this.apiFn(l1_2and3, params, 'age')
    },

    async apiFn(api, params, code) {
      params.type = code == 'sex' ? 'xb' : 'nl'
      const { resultCode, resultData } = await api(params)
      if (resultCode === '200' && resultData) {
        this.formatItemForData(code, resultData)
      }
    },
    formatItemForData(code, data) {
      if (code == 'sex') {
        console.log('l1_2 sex', data)
        this.sexList = data
        let seriesData = []
        data.map(item => {
          seriesData.push({
            name: item.name,
            value: item.num,
            itemStyle: { color: item.name == '男' ? '#0078FF' : '#FF00A2' }
          })
        })
        this.options.series[0].data = seriesData
      } else if (code == 'age') {
        console.log('l1_2 age', data)
        this.ageList = data
        let seriesData = []
        data.map(item => {
          seriesData.push({
            name: item.name,
            value: item.num
          })
        })
        this.options.series[1].data = seriesData
        this.empty = !this.sexList.length && !this.ageList.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);

  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
  .bg {
    position: absolute;
    top: 30%;
    width: 86x;
    height: 86px;
  }
}
</style>
