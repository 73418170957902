<template>
  <div class="com-box">
    <div class="tools">
      <div class="item-box space-between"></div>
    </div>
    <ek-chart
      :options="options"
      :animation="true"
      :animationData="options.xAxis.data"
    />
  </div>
</template>

<script>
import { l1_1 } from '@/api/do'

export default {
  data() {
    return {
      options: {
        color: ['#FF00A2', '#00FFC2', '#00C6FF'],
        title: {
          text: '单位(人次)',
          textStyle: {
            color: '#fff',
            fontSize: 18
          },
          left: -4
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let { dateType } = this.dateObj
            let unit = dateType == 'day' ? '时' : ''
            let date =
              dateType == 'day'
                ? this.dayjs(params[0].name).format('HH')
                : params[0].name
            let el = ''
            params.map(item => {
              el = el.concat(
                `<div class="text" style="font-size:18px;margin-top:5px;">
                    <div class="left">
                      <span>${item.marker}</span>
                      <span class="t">${item.seriesName}&nbsp&nbsp&nbsp</span>
                    </div>
                    <div class="right">
                      <span class="v">${item.value}人</span>
                    </div>
                  </div>`
              )
            })
            return `<div class="tooltip-box column">
                      <span class="b-t">${date + unit}</span>
                      ${el}
                    </div>`
          }
        },
        legend: {
          icon: 'circle',
          textStyle: {
            fontSize: 16,
            color: '#fff'
          }
        },
        grid: {
          top: '16%',
          left: '8%',
          right: '3%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: val => {
              let { dateType } = this.dateObj
              let unit =
                dateType == 'holiday'
                  ? '日'
                  : dateType == 'day'
                  ? '时'
                  : dateType == 'month'
                  ? '日'
                  : '月'
              let date = this.dayjs(val).format(
                dateType == 'holiday'
                  ? 'DD'
                  : dateType == 'day'
                  ? 'HH'
                  : dateType == 'month'
                  ? 'DD'
                  : 'MM'
              )
              return date + unit
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16
          }
        },
        series: [
          {
            name: '新增用户',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: []
          },
          {
            name: '日活用户',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: []
          },
          // {
          //   name: '用户总数',
          //   type: 'line',
          //   smooth: true,
          //   symbol: 'none',
          //   data: []
          // }
        ]
      },
      dateObj: null
    }
  },
  mounted() {
    this.$eventBus.$off('do-L1-1')
    this.$eventBus.$on('do-L1-1', obj => {
      this.dateObj = obj
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDay = obj.startDate
      if (obj.dateType == 'holiday') params.endDay = obj.endDate
      const { resultCode, resultData } = await l1_1(params)
      if (resultCode === '200' && resultData) {
        console.log('l1_1', resultData)
        const { loginUserNum, newUserNum, totalUserNum } = resultData

        if (obj.dateType == 'holiday' || obj.dateType == 'month') {
          this.options.xAxis.data = resultData.days
        } else if (obj.dateType == 'day') {
          this.options.xAxis.data = resultData.hours
        } else if (obj.dateType == 'year') {
          this.options.xAxis.data = resultData.months
        }
        this.options.series[0].data = newUserNum
        this.options.series[1].data = loginUserNum
        // this.options.series[2].data = totalUserNum
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  width: 100%;
  @include flex(column);
  .tools {
    @include flex(column);
  }
  .item-box {
    @include flex();
    align-items: center;
  }
  .item-box:first-child {
    margin-top: 15px;
  }
  .space-between {
    justify-content: space-between;
  }
  .flex-end {
    justify-content: flex-end;
  }
}

.item {
  margin-left: 30px;
}
</style>
