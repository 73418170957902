<template>
  <div class="com-box">
    <ek-item-info title="运营合作" />
    <div class="box">
      <div class="i-box">
        <!-- <div class="t-header">
          <span>序号</span>
          <span>平台</span>
          <span>合作单位/个人</span>
        </div>
        <div class="t-list" v-if="!empty">
          <swiper ref="orderSwiper" :options="swiperOptions">
            <swiper-slide v-for="(items, index) in list" :key="index">
              <div
                class="item"
                v-for="(item, index) in items"
                :key="index"
                @click="handleItem(item)"
                @mouseenter="onEnterClick"
                @mouseleave="onLeaveClick"
              >
                <span>{{ index + 1 }}</span>
                <span>{{ item.website_name }}</span>
                <span>{{ item.title }}</span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <ek-empty v-else></ek-empty> -->
        <el-image src="/images/components/do/r1_r3.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      list: [],
      empty: false,
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      }
    }
  },
  computed: {
    // swiper() {
    //   return this.$refs.orderSwiper.$swiper
    // }
  },
  mounted() {
    // this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await fetchData('cooperation')
      if (resultCode === '200' && resultData) {
        console.log('r1_3', resultData)
        let tempList = resultData.content.map((item, i) => {
          item.number = i + 1
          return item
        })
        this.list = this.groupList(tempList, 3)
        this.empty = !resultData.content.length
      }
    },
    handleItem(item) {
      console.log(item)
    },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  width: 100%;
  @include flex(column);
  justify-content: space-between;
  .box {
    @include flex();
    height: calc(100% - 36px);
    .i-box {
      @include flex(column);
      flex: 1;
      .t-header {
        @include flex();
        align-items: center;
        height: 60px;
        background: #202738;
        span {
          font-size: 20px;
          text-align: center;
        }
      }
      .t-list {
        @include flex(column);
        .swiper-container {
          height: 210px;
          width: 100%;
        }
        .item {
          width: 100%;
          @include flex();
          align-items: center;
          height: 70px;
          background: rgba($color: #202738, $alpha: 0.8);
          span {
            text-align: center;
            font-size: 20px;
          }
        }
      }

      .t-header > span:nth-child(1),
      .swiper-slide > .item > span:nth-child(1) {
        flex: 0.7;
      }
      .t-header > span:nth-child(2),
      .swiper-slide > .item > span:nth-child(2) {
        flex: 1;
      }
      .t-header > span:nth-child(3),
      .swiper-slide > .item > span:nth-child(3) {
        flex: 3;
      }

      .swiper-slide > .item:nth-child(odd) {
        background: rgba($color: #202738, $alpha: 0.5);
      }
    }
  }
}
</style>
