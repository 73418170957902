<template>
  <div class="com-box">
    <ek-item-info title="游客消费TOP5行业" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.yAxis[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { l2_4 } from '@/api/do'

export default {
  data() {
    return {
      options: {
        animation: true,
        grid: {
          top: '10%',
          left: '33%',
          right: '16%',
          bottom: '10%'
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params[0].seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params[0].marker}</span>
                          <span class="t">${params[0].name}</span>
                          <span class="v">${params[0].value}%</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            inverse: true,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,.5)'
              }
            },
            axisLabel: {
              interval: 0,
              formatter: function(value, index) {
                // console.log(index + 1, value)
                return `{b|${index + 1}.}{title|${value}} `
              },
              rich: {
                b: {
                  color: '#fff',
                  fontSize: 14,
                  align: 'left',
                  padding: [0, 5, 0, 0]
                },
                title: {
                  color: '#fff',
                  fontSize: 14,
                  align: 'left',
                  width: 60
                }
              }
            },
            data: []
          }
        ],
        color: [
          new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#33FF9B'
            },

            {
              offset: 1,
              color: '#019690'
            }
          ])
        ],
        series: [
          {
            name: '游客消费TOP5行业',
            type: 'bar',
            stack: 'Tik Tok',
            barWidth: 14,
            itemStyle: {
              borderRadius: [0, 10, 10, 0]
            },
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              formatter(e) {
                return e.value + '%'
              }
            },
            // data: [{ value: 99, name: '餐饮' }]
            data: []
          }
        ]
      },
      empty: false,
      dateObj: null
    }
  },
  mounted() {
    this.$eventBus.$off('do-L2-4')
    this.$eventBus.$on('do-L2-4', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l2_4(params)
      if (resultCode === '200' && resultData) {
        console.log('l2_4', resultData)
        let yAxisData = []
        let seriesData = []
        resultData.map(item => {
          yAxisData.push(item.merchantTradeName)
          let persent =
            parseInt(
              (item.transactionAmount / item.totalAmount).toFixed(4) * 10000
            ) / 100
          seriesData.push(persent)
        })
        this.options.yAxis[0].data = yAxisData
        this.options.series[0].data = seriesData

        this.empty = !resultData.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
