<template>
  <div class="com-box">
    <ek-item-info title="消费区域占比" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l2_2 } from '@/api/do'

export default {
  data() {
    return {
      list: [],
      total: 0,
      options: {
        color: ['#0078FF', '#FF00A2'],
        title: {
          text: '消费额',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            fontWeight: 500
          },
          left: -5
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${parseFloat(
                            (params.value / 10000).toFixed(2)
                          )}万元</span>
                          <span class="v">&nbsp${params.percent}%</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          bottom: 0,
          icon: 'roundRect',
          itemWidth: 16,
          itemHeight: 6,
          itemGap: 20,
          textStyle: {
            color: '#fff',
            fontSize: 16
          },
          formatter: val => {
            let persent = 0
            if (this.list.length) {
              this.list.map(item => {
                if (item.sourceType == val) {
                  persent = parseInt(
                    (item.transactionAmount / this.total).toFixed(2) * 100
                  )
                }
              })
            }
            return `${val}  ${persent}%`
          }
        },
        series: [
          {
            name: '消费区域占比',
            type: 'pie',
            center: ['50%', '40%'],
            radius: '50%',
            label: {
              show: false
            },
            data: []
          }
        ]
      },
      empty: false,
      dateObj: null
    }
  },
  mounted() {
    this.$eventBus.$off('do-L2-2')
    this.$eventBus.$on('do-L2-2', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l2_2(params)
      if (resultCode === '200' && resultData) {
        let seriesData = []
        let total = 0
        resultData.map(item => {
          seriesData.push({
            name: item.sourceType,
            value: item.transactionAmount
          })
          total += item.transactionAmount
        })
        this.options.series[0].data = seriesData
        this.total = total
        console.log('l2_2', resultData)
        this.list = resultData
        this.empty = !resultData.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
  .bg {
    position: absolute;
    top: 30%;
    width: 86x;
    height: 86px;
  }
}
</style>
