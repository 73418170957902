<template>
  <div class="container">
    <ek-panel className="do-panel" title="平台运营">
      <div class="box">
        <div class="item" style="height: 32%;"><r1_1></r1_1></div>
        <div class="item" style="height:32%"><r1_2></r1_2></div>
        <div class="item" style="height:32%"><r1_3></r1_3></div>
      </div>
      <!-- <div class="box" v-if="currIndex == 1">
        <div class="item" style="height: 42%;"><r2_1></r2_1></div>
        <div class="item" style="height: 27.8%;"><r2_2></r2_2></div>
        <div class="item" style="height: 26.6%;"><r2_3></r2_3></div>
      </div> -->
    </ek-panel>
  </div>
</template>

<script>
import r1_1 from './components/R1/r1'
import r1_2 from './components/R1/r2'
import r1_3 from './components/R1/r3'

// import r2_1 from './components/R2/r1'
// import r2_2 from './components/R2/r2'
// import r2_3 from './components/R2/r3'

export default {
  components: { r1_1, r1_2, r1_3 },
  data() {
    return {
      // currIndex: 0
    }
  },
  methods: {
    // handleTitle(i) {
    //   this.currIndex = i
    // }
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  .do-panel {
    height: 100%;
  }
  .box {
    @include flex(column);
    justify-content: space-between;
    padding: 25px;
    height: calc(100% - 50px);
  }
  .item {
    @include flex();
    justify-content: space-between;
  }
}
</style>
