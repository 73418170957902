<template>
  <div class="com-box">
    <ek-item-info title="客源地分析" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l1_4 } from '@/api/do'

export default {
  data() {
    return {
      list: [],
      total: 0,
      options: {
        color: ['#FFAA45', '#38FCBE'],
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let val =
              params.value > 10000
                ? parseFloat(params.value / 10000).toFixed(2) + '万'
                : params.value
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${val}人</span>
                          <span class="v">&nbsp${params.percent}%</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          bottom: 0,
          icon: 'roundRect',
          itemWidth: 16,
          itemHeight: 6,
          itemGap: 20,
          textStyle: {
            color: '#fff',
            fontSize: 16
          },
          formatter: val => {
            let persent = 0
            if (this.list.length) {
              this.list.map(item => {
                if (item.fenceType == val) {
                  persent = parseInt((item.num / this.total).toFixed(2) * 100)
                }
              })
            }
            return `${val}  ${persent}%`
          }
        },
        series: [
          {
            name: '客源地分析',
            type: 'pie',
            center: ['50%', '40%'],
            radius: '50%',
            label: {
              show: false
            },
            itemStyle: {
              borderColor: '#0E1517',
              borderWidth: 3
            },
            selectedMode: 'single',
            data: []
          }
        ]
      },
      empty: false
    }
  },
  mounted() {
    this.$eventBus.$off('do-L1-4')
    this.$eventBus.$on('do-L1-4', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l1_4(params)
      if (resultCode === '200' && resultData) {
        let seriesData = []
        let total = 0
        resultData.map(item => {
          seriesData.push({
            name: item.fenceType,
            value: item.num
          })
          total += item.num
        })
        this.options.series[0].data = seriesData
        this.total = total
        console.log('l1_4', resultData)
        this.list = resultData
        this.empty = !resultData.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
