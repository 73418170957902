<template>
  <div class="container do">
    <div class="fade-left">
      <left />
    </div>
    <div class="center">
      <center />
    </div>
    <div class="fade-right">
      <right />
    </div>
  </div>
</template>

<script>
import Left from './Left'
import Center from './Center'
import Right from './Right'

export default {
  components: {
    Left,
    Center,
    Right
  }
}
</script>

<style scoped lang="scss">
.do {
  @include flex(row);
  align-items: center;
  justify-content: center;
  position: relative;
  .fade-left,
  .fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $barWidth;
    pointer-events: all;
    padding: 20px 30px 30px 30px;
  }

  .fade-left {
    left: 0;
    animation: fadeInLeft 1s ease;
    padding: 5px 0 18px 50px;
  }
  .fade-right {
    right: 0;
    animation: fadeInRight 1s ease;
    padding: 5px 50px 18px 0px;
  }
  .center {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $centerWidth;
    padding: 0 50px 18px;
  }
}
</style>
