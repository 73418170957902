<template>
  <div class="com-box">
    <ek-item-info title="客源地城市TOP5" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { l1_5 } from '@/api/do'

export default {
  data() {
    return {
      options: {
        animation: true,
        grid: {
          top: '10%',
          left: '27%',
          right: '12%',
          bottom: 0
        },
        title: {
          text: '(本市除外)',
          top: '2%',
          textStyle: {
            color: '#fff',
            fontSize: 14,
            fontWeight: 500
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params[0].name}：</span>
                        <span class="v">${params[0].value}%</span>
                      </div>
                    </div>`
            return el
          }
        },
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            inverse: true,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,.5)'
              }
            },
            data: [],
            axisLabel: {
              interval: 0,
              formatter: function(value, index) {
                return `{b|${index + 1}.}{title|${value}} `
              },
              rich: {
                b: {
                  color: '#fff',
                  fontSize: 14,
                  padding: [0, 5, 0, 0]
                },
                title: {
                  color: '#fff',
                  fontSize: 14
                }
              }
            }
          }
        ],
        color: [
          new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#33FF9B'
            },

            {
              offset: 1,
              color: '#019690'
            }
          ])
        ],
        series: [
          {
            type: 'bar',
            barWidth: 14,
            itemStyle: {
              borderRadius: [0, 10, 10, 0]
            },
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              formatter: function(e) {
                return e.value + '%'
              }
            },
            data: []
          }
        ]
      },
      empty: false
    }
  },
  mounted() {
    this.$eventBus.$off('do-L1-5')
    this.$eventBus.$on('do-L1-5', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l1_5(params)
      if (resultCode === '200' && resultData) {
        let yAxisData = []
        let seriesData = []
        resultData.map(item => {
          yAxisData.push(item.city)
          let persent = parseInt((item.num / item.totalNum).toFixed(2) * 100)
          seriesData.push(persent)
        })
        this.options.yAxis[0].data = yAxisData
        this.options.series[0].data = seriesData
        this.empty = !resultData.length
        console.log('l1_5', resultData)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
