<template>
  <div class="com-box">
    <ek-item-info title="餐饮偏好" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l1_6and7 } from '@/api/do'
import { compare } from '@/utils/tools'

export default {
  data() {
    return {
      total: 0,
      list: [],
      options: {
        color: [
          '#FFAC4A',
          '#23CCD2',
          '#FF5150',
          '#0FADFC',
          '#38FCBE',
          '#FF00A2'
        ],
        title: {
          text: '餐饮偏好',
          top: '45%',
          left: '28%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 14,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let val =
              params.value > 10000
                ? parseFloat(params.value / 10000).toFixed(2) + '万'
                : params.value
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${val}人</span>
                          <span class="v">&nbsp${params.percent}%</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          right: 0,
          icon: 'circle',
          itemWith: 14,
          itemHeight: 14,
          textStyle: {
            color: '#fff',
            fontSize: 13
          },
          formatter: val => {
            let persent = 0
            if (this.list.length) {
              this.list.map(item => {
                if (item.name == val) {
                  persent =
                    parseInt((item.num / this.total).toFixed(4) * 10000) / 100
                }
              })
            }

            return `${val}  ${persent}%`
          }
        },
        series: [
          {
            name: '餐饮偏好',
            type: 'pie',
            center: ['30%', '50%'],
            radius: ['40%', '60%'],
            label: {
              show: false
            },
            itemStyle: {
              borderColor: '#0E1517',
              borderWidth: 10
            },
            selectedMode: 'single',
            data: []
          }
        ]
      },
      empty: false
    }
  },
  components: {},
  mounted() {
    this.$eventBus.$off('do-L1-7')
    this.$eventBus.$on('do-L1-7', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      this.total = 0
      let params = {
        dateType: obj.dateType,
        type: 'cy'
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l1_6and7(params)
      if (resultCode === '200' && resultData) {
        this.list = resultData
        let seriesData = []
        let total = 0
        resultData.map(item => {
          seriesData.push({
            name: item.name,
            value: item.num
          })
          total += item.num
        })
        this.total = total
        this.options.series[0].data = seriesData.sort(compare('value', false))
        this.empty = !resultData.length
        console.log('l1_7', resultData)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
