<template>
  <div class="com-box">
    <ek-item-info title="学历分布" />
    <div class="box">
      <ek-chart v-if="!empty" :options="options" />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l1_2and3 } from '@/api/do'

export default {
  data() {
    return {
      list: [],
      total: 0,
      options: {
        color: ['#13CECF', '#5DA8F9', '#F5F5F5'],
        tooltip: {
          trigger: 'item',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let val =
              params.value > 10000
                ? parseFloat(params.value / 10000).toFixed(2) + '万'
                : params.value
            let el = ''
            el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}学历：</span>
                        <span class="v">${val}人</span>
                        <span class="v">&nbsp${params.percent}%</span>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          bottom: '5%',
          icon: 'triangle',
          itemWidth: 14,
          itemHeight: 12,
          itemGap: 10,
          formatter: val => {
            let persent = 0
            if (this.list.length) {
              this.list.map(item => {
                if (item.name.indexOf(val) != -1) {
                  persent = parseInt((item.num / this.total).toFixed(2) * 100)
                }
              })
            }
            let type =
              val == '高' ? 'g' : val == '中' ? 'z' : val == '低' ? 'd' : ''
            return `{${type}|${val}：${persent}%}`
          },
          textStyle: {
            rich: {
              g: {
                color: '#13CECF',
                fontSize: 15
              },
              z: {
                color: '#5DA8F9',
                fontSize: 15
              },
              d: {
                color: '#F5F5F5',
                fontSize: 15
              }
            }
          }
        },
        series: [
          {
            type: 'funnel',
            left: '12%',
            right: '40%',
            top: '15%',
            bottom: '20%',
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 10,
            itemStyle: {
              //去掉默认白色边框线
              borderWidth: 0,
              shadowBlur: 20,
              shadowOffsetX: 0,
              shadowOffsetY: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            label: {
              fontSize: 15
            },
            labelLine: {
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: '#fff'
              }
            },
            data: [
              {
                value: 0,
                name: '高',
                label: {
                  color: '#13CECF'
                },
                labelLine: {
                  length: 102
                }
              },
              {
                value: 0,
                name: '中',
                label: {
                  color: '#5DA8F9'
                },
                labelLine: {
                  length: 80
                }
              },
              {
                value: 0,
                name: '低',
                label: {
                  color: '#F5F5F5'
                },
                labelLine: {
                  length: 57
                }
              }
            ]
          }
        ]
      },
      empty: false
    }
  },
  mounted() {
    this.$eventBus.$off('do-L1-3')
    this.$eventBus.$on('do-L1-3', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      this.total = 0
      let params = {
        dateType: obj.dateType,
        type: 'xl'
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l1_2and3(params)
      if (resultCode === '200' && resultData) {
        this.list = resultData
        let seriesData = this.options.series[0].data
        let total = 0
        resultData.map(item => {
          seriesData.map(item2 => {
            if (item.name.indexOf(item2.name) != -1) {
              item2.value = item.num
              total += item.num
              return item2
            }
          })
        })
        this.total = total
        this.options.series[0].data = seriesData
        this.empty = !resultData.length
        console.log('l1_3', seriesData)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
