<template>
  <div class="com-box">
    <ek-item-info title="游客消费TOP5商户(消费额)" />
    <div class="box">
      <div class="t-list" v-if="!empty">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="handleListItem(item)"
        >
          <span>TOP{{ index + 1 }}.</span>
          <el-tooltip
            effect="dark"
            :content="item.merchantName"
            placement="top-start"
          >
            <span>{{ item.merchantName }}</span>
          </el-tooltip>
        </div>
      </div>
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l2_5 } from '@/api/do'

export default {
  data() {
    return {
      empty: false,
      list: []
    }
  },
  mounted() {
    this.$eventBus.$off('do-L2-5')
    this.$eventBus.$on('do-L2-5', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l2_5(params)
      if (resultCode === '200' && resultData) {
        console.log('l2_5', resultData)
        this.list = resultData
        this.empty = !resultData.length
      }
    },

    handleListItem(item) {
      console.log(item)
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    padding: 15px 15px 15px 0;
    position: relative;
    @include flex();
    justify-content: center;
    .t-list {
      @include flex(column);
      flex: 1;
      width: 100%;
      .item {
        width: 100%;
        @include flex();
        align-items: center;
        height: 25%;
        background: rgba($color: #202738, $alpha: 0.5);
        span {
          // text-align: center;
          padding: 0 5px;
          font-size: 16px;
          white-space: nowrap;
        }
      }
    }
    .t-list > .item > span:nth-child(1) {
      flex: 1;
      text-align: center;
    }
    .t-list > .item > span:nth-child(2) {
      flex: 3;
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .t-list > .item:nth-child(odd) {
      background: rgba($color: #202738, $alpha: 0.8);
    }
  }
}
</style>
