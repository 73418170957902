<template>
  <div class="com-box">
    <div class="tools">
      <div class="margin-bottom">
        <ek-item-info title="交易趋势" fontSize="22px" />
      </div>
      <div class="item-box space-between" v-if="!empty">
        <ek-item-info
          fontSize="20px"
          title="交易额："
          :value="`${total}万元`"
        />
        <chart-legend />
      </div>
    </div>
    <div class="chart-box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import chartLegend from '../chartLegend'
import { l2_1 } from '@/api/do'

export default {
  data() {
    return {
      empty: false,
      options: {
        title: {
          text: '单位(万元)',
          textStyle: {
            color: '#fff',
            fontSize: 18
          },
          left: -4
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            params.map(item => {
              el = el.concat(
                `<div class="text" style="font-size:18px;margin-top:5px;">
                    <div class="left">
                      <span class="t">${item.seriesName}&nbsp&nbsp&nbsp</span>
                    </div>
                    <div class="right">
                      <span class="v">${parseFloat(
                        (item.value / 10000).toFixed(2)
                      )}万元</span>
                    </div>
                  </div>`
              )
            })
            return `<div class="tooltip-box column center">
                      <span class="b-t">${params[0].name}</span>
                      ${el}
                    </div>`
          }
        },
        grid: {
          top: '16%',
          left: '8%',
          right: '3%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: val => {
              let { dateType } = this.dateObj
              let unit =
                dateType == 'holiday'
                  ? '日'
                  : dateType == 'month'
                  ? '日'
                  : dateType == 'year'
                  ? '月'
                  : ''
              let date =
                dateType != 'day'
                  ? this.dayjs(val).format(
                      dateType == 'holiday'
                        ? 'DD'
                        : dateType == 'month'
                        ? 'DD'
                        : 'MM'
                    )
                  : val
              return date + unit
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return parseFloat((val / 10000).toFixed(2))
            }
          }
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '交易额',
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#EF9C00'
            },
            markPoint: {
              symbol: 'circle',
              symbolSize: 10,
              label: {
                position: 'insideTop',
                color: '#fff',
                fontSize: 14,
                formatter: params => {
                  return parseFloat((params.value / 10000).toFixed(2)) + '万元'
                }
              },
              data: [
                {
                  type: 'max',
                  itemStyle: {
                    color: '#EF9C00'
                  }
                },
                {
                  type: 'min',
                  itemStyle: {
                    color: '#00C0FF'
                  }
                }
                // {
                //   type: 'average',
                //   itemStyle: {
                //     color: '#fff'
                //   }
                // }
              ]
            },
            markLine: {
              data: [
                {
                  type: 'average',
                  name: 'Avg',
                  // symbol: 'none',
                  label: {
                    color: '#fff',
                    fontSize: 14,
                    position: 'insideEndTop',
                    formatter: params => {
                      return (
                        parseFloat((params.value / 10000).toFixed(2)) + '万元'
                      )
                    }
                  },
                  lineStyle: {
                    color: '#EF003E',
                    type: [3, 5]
                  }
                }
              ]
            },
            data: []
          }
        ]
      },
      total: 0,
      dateObj: null
    }
  },
  components: { chartLegend },
  mounted() {
    this.$eventBus.$off('do-L2-1')
    this.$eventBus.$on('do-L2-1', obj => {
      this.dateObj = obj
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l2_1(params)
      if (resultCode === '200' && resultData) {
        console.log('l2_1', resultData)
        let xAxisData = []
        let seriesData = []
        let total = 0
        resultData.map(item => {
          xAxisData.push(item.date)
          seriesData.push(item.transactionAmount)
          total += item.transactionAmount
        })
        this.total = parseFloat((total / 10000).toFixed(2))
        this.options.xAxis.data = xAxisData
        this.options.series[0].data = seriesData
        this.empty = !resultData.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  width: 100%;
  @include flex(column);
  .tools {
    @include flex(column);
    .item-box {
      @include flex();
      align-items: center;
    }
    .item-box:first-child {
      margin-top: 15px;
    }
    .space-between {
      justify-content: space-between;
    }
    .flex-end {
      justify-content: flex-end;
    }
    .margin-bottom {
      margin-bottom: 20px;
    }
  }

  .chart-box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
