<template>
  <div class="com-box">
    <ek-item-info title="消费偏好" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import { l2_3 } from '@/api/do'
import { compare } from '@/utils/tools'

export default {
  data() {
    return {
      colors: [
        '#00BBC6',
        '#006AFF',
        '#f7cf00',
        '#EE2FCE',
        '#FF7700',
        '#00B6FF',
        '#00C276',
        '#9700FF',
        '#d16470'
      ],
      options: {
        title: {
          text: '笔数',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            fontWeight: 500
          },
          left: -5
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${(params.value / 10000).toFixed(
                            2
                          )}万笔</span>
                          <span class="v">&nbsp${params.percent}%</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          right: 0,
          icon: 'circle',
          itemWith: 14,
          itemHeight: 14,
          formatter: val => {
            let persent = 0
            let amount = 0
            if (this.list.length) {
              this.list.map(item => {
                if (item.name == val) {
                  persent = parseInt(
                    (item.amount / this.total).toFixed(2) * 100
                  )
                  amount = parseFloat((item.amount / 10000).toFixed(2))
                }
              })
            }
            return `${val}  ${persent}%\n{${this.typeCode(val)}|${amount}万笔}`
          },
          type: 'scroll',
          pageTextStyle: {
            color: '#fff'
          },
          textStyle: {
            color: '#fff',
            fontSize: 14,
            lineHeight: 20,
            rich: {}
          }
        },
        series: [
          {
            name: '消费偏好',
            type: 'pie',
            center: ['27%', '50%'],
            radius: ['30%', '50%'],
            label: {
              show: false
            },
            itemStyle: {
              borderColor: '#050A0F',
              borderWidth: 10
            },
            selectedMode: 'single',
            data: []
          }
        ]
      },
      total: 0,
      list: [],
      empty: false,
      dateObj: null
    }
  },
  mounted() {
    this.$eventBus.$off('do-L2-3')
    this.$eventBus.$on('do-L2-3', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l2_3(params)
      if (resultCode === '200' && resultData) {
        console.log('l2_3', resultData)
        this.list = resultData
        let seriesData = []
        let total = 0
        resultData.map(item => {
          seriesData.push({
            name: item.name,
            value: item.amount
          })
          total += item.amount
          let key = this.typeCode(item.name)
          this.options.legend.textStyle.rich[key] = {
            // color: this.colors[i],
            fontSize: 14,
            padding: [5, 0]
          }
        })
        this.total = total
        this.options.color = this.colors
        this.options.series[0].data = seriesData.sort(compare('value', false))

        this.empty = !resultData.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
