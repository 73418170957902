<template>
  <div class="com-box">
    <ek-item-info title="活动推广" />
    <div class="box">
      <div class="left">
        <div class="text" v-for="(item, index) in totals" :key="index">
          <span class="t">{{ item.title }}</span>
          <span class="v"
            ><count-to
              separator=""
              :startVal="0"
              :endVal="item.num || 0"
              :duration="2000"
            /><span class="unit">{{ item.unit }}</span></span
          >
        </div>
      </div>
      <div class="right">
        <div class="tools">
          <div class="btn-box">
            <div
              class="btn"
              :class="item.select ? 'active' : ''"
              v-for="(item, index) in typeBtns"
              :key="index"
              @click="handleBtn('type', index)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="btn-box">
            <div
              class="btn"
              :class="sortIndex == index ? 'active' : ''"
              v-for="(item, index) in sortBtns"
              :key="index"
              @click="handleBtn('sort', index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="i-box" v-if="!empty">
          <div class="t-header">
            <span>序号</span>
            <span>标题</span>
            <span>类别</span>
            <span>曝光量</span>
            <!-- <span>参与度 </span> -->
          </div>
          <div class="t-list">
            <swiper
              :ref="`orderSwiper-${curBtnIndex}`"
              :key="swiperKey"
              :options="swiperOptions"
            >
              <swiper-slide v-for="(items, index) in list" :key="index">
                <div
                  class="item"
                  v-for="(item, index) in items"
                  :key="index"
                  @click="handleItem(item)"
                  @mouseenter="onEnterClick"
                  @mouseleave="onLeaveClick"
                >
                  <span>{{ item.number }}</span>
                  <span>{{ item.title }}</span>
                  <span>{{ item.type }}</span>
                  <span>{{
                    item.exposureCount ? item.exposureCount + '次' : '--'
                  }}</span>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <ek-empty v-else></ek-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { r1_1 } from '@/api/do'

export default {
  data() {
    return {
      typeBtns: [
        { label: '活动', select: true },
        { label: '文章', select: true }
      ],
      sortBtns: ['最新', '按热度排行'],
      curBtnIndex: '',
      sortIndex: 0,
      totals: [
        { title: '活动数量', num: 0, unit: '场' },
        { title: '文章数', num: 0, unit: '篇' },
        { title: '曝光度', num: 0, unit: '次' }
      ],
      list: [],
      empty: false,
      swiperKey: Math.random(this.dayjs().valueOf()),
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs[`orderSwiper-${this.curBtnIndex}`].$swiper
    }
  },
  mounted() {
    this.fetchTotal()
    this.fetchList()
  },
  methods: {
    async fetchList() {
      let params = {
        needDraft: true,
        page: 0,
        size: 50,
        status: '1'
      }
      let activity = this.typeBtns[0]
      let article = this.typeBtns[1]
      if (activity.select && !article.select) params.typeCode = 'signup_manage'
      if (
        (activity.select && article.select) ||
        (!activity.select && !article.select)
      )
        params.typeCodeArr = ['gonglue', 'signup_manage', 'bus_imformation']
      if (!activity.select && article.select)
        params.typeCodeArr = ['gonglue', 'bus_imformation']
      if (this.sortIndex == 0)
        params.sortParam = {
          create_date: 'DESC'
        }
      if (this.sortIndex == 1)
        params.sortParam = {
          view_count: 'DESC'
        }

      const { resultCode, resultData } = await this.$axios.post(
        '/cmsv2/content/page',
        params
      )
      if (resultCode === '200' && resultData) {
        console.log('r1_1 list', resultData)

        let tempList = resultData.content.map((item, i) => {
          item.number = i + 1
          item.type = this.typeCode(item.type_code)
          item.exposureCount = item.view_count
          item.engagement = item.signup_ed_num
          return item
        })
        this.list = this.groupList(tempList, 3)
        this.swiperKey = Math.random(this.dayjs().valueOf())
        this.empty = !resultData.content.length
      }
    },
    async fetchTotal() {
      const { resultCode, resultData } = await r1_1({})
      if (resultCode === '200' && resultData) {
        console.log('r1_1 total', resultData)
        this.totals[0].num = resultData.activityNum
        this.totals[1].num = resultData.articleNum
        this.totals[2].num = resultData.readingCount
      }
    },
    handleBtn(type, index) {
      this.curBtnIndex = type + Math.random(this.dayjs().valueOf())
      if (type == 'type') {
        let item = this.typeBtns[index]
        item.select = !item.select
      } else if (type == 'sort') {
        this.sortIndex = index
      }
      this.fetchList()
    },

    handleItem(item) {
      this.$eventBus.$emit('do_activity-detail', item.content)
    },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  width: 100%;
  @include flex(column);
  justify-content: space-between;
  .box {
    @include flex();
    height: calc(100% - 34px);
    .left {
      width: 190px;
      @include flex(column);
      justify-content: center;
      background: #202738;
      padding: 0 20px;
      margin-right: 20px;
      .text {
        @include flex(column);
        align-items: center;
        padding: 10px 15px;
        border-bottom: 1px solid #4a546b;
        .v {
          @include flex();
          align-items: baseline;
          font-size: 32px;
          color: #0af7bc;
          font-weight: bold;
          margin-top: 7px;
        }
        .t {
          // margin-top: 10px;
        }
        .unit {
          font-size: 20px;
        }
      }
      .text:last-child {
        border-bottom: none;
      }
    }
    .right {
      flex: 1;
      width: 100%;
      @include flex(column);
      justify-content: space-between;
      .tools {
        @include flex();
        justify-content: space-between;
        // height: 34px;
        .btn-box {
          @include flex();
          // width: 100%;
        }

        .btn {
          padding: 7px 17px;
          border: 1px solid #ef9c00;
          border-radius: 6px;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
        .btn:first-child {
          margin-right: 10px;
        }
        .active {
          color: #ef9c00;
        }
      }
      .i-box {
        @include flex(column);
        width: 671px;
        .t-header {
          @include flex();
          align-items: center;
          height: 40px;
          background: #202738;
          span {
            font-size: 19px;
            text-align: center;
          }
        }

        .t-list {
          @include flex(column);
          .swiper-container {
            height: 180px;
            width: 100%;
          }
          .item {
            width: 100%;
            @include flex();
            align-items: center;
            height: 60px;
            background: rgba($color: #202738, $alpha: 0.8);
            cursor: pointer;
            span {
              text-align: center;
              font-size: 19px;
            }
          }
        }

        .t-header > span:nth-child(1),
        .swiper-slide > .item > span:nth-child(1) {
          flex: 0.7;
        }
        .t-header > span:nth-child(2),
        .swiper-slide > .item > span:nth-child(2) {
          flex: 2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .t-header > span:nth-child(3),
        .swiper-slide > .item > span:nth-child(3) {
          flex: 0.5;
        }
        .t-header > span:nth-child(4),
        .swiper-slide > .item > span:nth-child(4) {
          flex: 0.5;
        }
        // .t-header > span:nth-child(5),
        // .swiper-slide > .item > span:nth-child(5) {
        //   flex: 1;
        // }

        .swiper-slide > .item:nth-child(odd) {
          background: rgba($color: #202738, $alpha: 0.5);
        }
      }
    }
  }
}
</style>
