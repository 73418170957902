<template>
  <div class="com-box">
    <div class="i-title">
      <ek-item-info title="商家入驻平台情况" />
    </div>
    <div class="i-box">
      <div class="t-box">
        <div class="t-item" v-for="(item, index) in list" :key="index">
          <div class="text">
            <span class="title">{{ item.title }}</span>
            <span class="num">
              <count-to
                separator=""
                :startVal="0"
                :endVal="item.num || 0"
                :duration="2000"
              />
              <span class="unit">{{ item.unit }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="item">
        <ek-chart
          v-if="!empty"
          :options="options"
          :animation="true"
          :animationData="options.series[0].data"
        />
        <ek-empty v-else></ek-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { cs_r3_total } from '@/api/cs'

export default {
  data() {
    return {
      list: [
        { title: '商家总数', num: 0, unit: '家' },
        { title: '入驻本平台商家数', num: 0, unit: '家' },
        { title: '入驻其他平台商家数', num: 0, unit: '家' },
        { title: '平台入住率', num: 0, unit: '%' }
      ],
      empty: false,
      total: 0,
      options: {
        color: ['#0CCE70', '#EF9C00'],
        title: {
          text: `商家入驻\n平台率`,
          top: '44%',
          left: '49%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 18,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            el = `<div class="tooltip-box column">
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="left">
                        <span class="t">${params.name}：</span>
                      </div>
                      <div class="right">
                          <span class="v">${params.percent}%</span>
                        </div>
                      </div
                    </div>`
            return el
          }
        },
        series: [
          {
            name: '商家入驻平台率',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['36%', '70%'],
            label: {
              color: '#fff',
              formatter: `{b}\n{d}%`,
              fontSize: 18
            },
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await cs_r3_total()
      if (resultCode === '200' && resultData) {
        this.list[0].num = resultData.storeNum
        this.list[1].num = resultData.ownPlatformNum
        this.list[2].num = resultData.outPlatformNum
        this.list[3].num = parseFloat(resultData.enteringRate * 100)

        let total = resultData.storeNum
        this.options.series[0].data = [
          {
            name: '未入驻平台商家',
            value: (
              parseFloat((total - resultData.platformNum) / total) * 100
            ).toFixed(2)
          },
          {
            name: '入驻平台商家',
            value: (parseFloat(resultData.platformNum / total) * 100).toFixed(2)
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  width: 100%;
  @include flex(column);
  .i-title {
    margin-bottom: 13px;
  }
  .i-box {
    @include flex();
    flex: 1;
    .t-box {
      @include flex();
      flex-wrap: wrap;
      width: 45%;
      background: url('/images/components/ov/br/r1-bg.png') no-repeat;
      background-size: 100% 100%;
      padding: 10px 20px;

      .t-item {
        width: calc(50% - 15px);
        @include flex();
        margin-right: 15px;
        position: relative;
      }

      .text {
        @include flex(column);
        justify-content: center;
        white-space: nowrap;
      }
      .title {
        font-size: 19px;
      }
      .num {
        font-size: 29px;
        color: #ef9c00;
        font-family: PangMenZhengDao;
        font-weight: bold;
        margin-top: 20px;
      }
      .unit {
        font-size: 16px;
      }

      .t-item:nth-child(even) {
        width: 50%;
        margin-right: 0;
      }

      .t-item:nth-child(1)::after,
      .t-item:nth-child(2)::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
          244deg,
          rgba(239, 156, 0, 0) 0%,
          rgba(239, 156, 0, 1) 50%,
          rgba(239, 156, 0, 0) 100%
        );
      }
    }
    .item {
      flex: 1;
      position: relative;
      @include flex();
      justify-content: center;
    }
  }
}
</style>
