<template>
  <div class="com-box">
    <ek-item-info title="出行方式偏好" />
    <div class="box">
      <ek-chart
        v-if="!empty"
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
      <ek-empty v-else></ek-empty>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { l1_6and7 } from '@/api/do'

export default {
  data() {
    return {
      options: {
        animation: true,
        grid: {
          top: '10%',
          left: '30%',
          right: '12%',
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params[0].name}：</span>
                        <span class="v">${params[0].value}%</span>
                      </div>
                    </div>`
            return el
          }
        },
        xAxis: [
          {
            type: 'value',
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            inverse: true,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,.5)'
              }
            },
            axisLabel: {
              interval: 0,
              formatter: function(value) {
                // console.log(index + 1, value)
                return `{title|${value}} `
              },
              rich: {
                title: {
                  color: '#fff',
                  fontSize: 14,
                  align: 'left',
                  width: 60
                }
              }
            },
            data: []
          }
        ],
        color: [
          new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#00C6FF'
            },

            {
              offset: 1,
              color: '#0072FF'
            }
          ]),
          new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#FEB47B'
            },

            {
              offset: 1,
              color: '#FF7E5F'
            }
          ])
        ],
        series: [
          {
            type: 'bar',
            barWidth: 14,
            barGap: '35%',
            itemStyle: {
              borderRadius: [0, 10, 10, 0]
            },
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              formatter: function(e) {
                return e.value + '%'
              }
            },
            data: []
          }
        ]
      },
      empty: false
    }
  },
  mounted() {
    this.$eventBus.$off('do-L1-6')
    this.$eventBus.$on('do-L1-6', obj => {
      this.fetchData(obj)
    })
  },
  methods: {
    async fetchData(obj) {
      let params = {
        dateType: obj.dateType,
        type: 'cx'
      }
      if (obj.dateType == 'holiday') params.startDate = obj.startDate
      if (obj.dateType == 'holiday') params.endDate = obj.endDate
      const { resultCode, resultData } = await l1_6and7(params)
      if (resultCode === '200' && resultData) {
        let yAxisData = []
        let seriesData = []
        let total = 0
        resultData.map(item => (total += item.num))
        resultData.map(item => {
          yAxisData.push(item.name)
          let persent = parseInt((item.num / total).toFixed(2) * 100)
          seriesData.push(persent)
        })
        this.options.yAxis[0].data = yAxisData.sort((a, b) => b - a)
        this.options.series[0].data = seriesData.sort((a, b) => b - a)
        this.empty = !resultData.length
        console.log('l1_6', resultData)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-box {
  height: 100%;
  @include flex(column);
  .box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
