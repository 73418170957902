<template>
  <div class="container">
    <ek-panel
      className="do-panel"
      :titles="['用户分析', '交易分析']"
      :segementIndex="currIndex"
      @click="handleTitle"
    >
      <div class="box" v-if="currIndex == 0">
        <div class="column" style="height: 34%;">
          <div class="item-box">
            <ek-item-info title="线上用户" />
            <div class="tools-box">
              <ek-select
                :list="dropDwons.holiday"
                :defaultVal="formData.holidayVal"
                width="100px"
                @click="handleHoliday"
              />
              <div style="margin-left:30px ;">
                <ek-segements
                  :index="formData.dateVal"
                  :list="segements"
                  @click="handleDate"
                  :defaultSelect="formData.holidayVal ? false : true"
                />
              </div>
            </div>
          </div>
          <l1_1></l1_1>
        </div>
        <ek-item-info
          :title="
            `游客画像（${
              formData.dateVal == 0 ? '日' : formData.dateVal == 1 ? '月' : '年'
            }）`
          "
        />
        <div class="item" style="height:32%">
          <div class="i-box" style="width: 35%;"><l1_2></l1_2></div>
          <div class="i-box" style="width: 32%;"><l1_3></l1_3></div>
          <div class="i-box" style="width: 33%;"><l1_4></l1_4></div>
        </div>
        <div class="item" style="height:28%">
          <div class="i-box" style="width:32% ;"><l1_5></l1_5></div>
          <div class="i-box" style="width: 32%;"><l1_6></l1_6></div>
          <div class="i-box" style="width:34%;"><l1_7></l1_7></div>
        </div>
      </div>
      <div class="box" v-if="currIndex == 1">
        <div class="column" style="height: 34%;">
          <div class="tools-box">
            <ek-select
              :list="dropDwons.holiday"
              :defaultVal="formData.holidayVal"
              width="100px"
              @click="handleHoliday"
            />
            <div style="margin-left:30px ;">
              <ek-segements
                :index="formData.dateVal"
                :list="segements"
                @click="handleDate"
                :defaultSelect="formData.holidayVal ? false : true"
              />
            </div>
          </div>
          <l2_1></l2_1>
        </div>
        <div class="item" style="height: 32.2%;">
          <div class="i-box" style="width: 50%;"><l2_2></l2_2></div>
          <!-- <div class="i-box" style="width: 32%;"><l2_3></l2_3></div> -->
          <div class="i-box" style="width: 50%;"><l2_4></l2_4></div>
        </div>
        <div class="item" style="height: 27.8%;">
          <div class="i-box" style="width:32% ;"><l2_5></l2_5></div>
          <div class="i-box" style="width: 32%;"><l2_6></l2_6></div>
          <div class="i-box" style="width:34%;"><l2_7></l2_7></div>
        </div>
      </div>
    </ek-panel>
  </div>
</template>

<script>
import { holiday } from '@/api/other'

import l1_1 from './components/L1/l1'
import l1_2 from './components/L1/l2'
import l1_3 from './components/L1/l3'
import l1_4 from './components/L1/l4'
import l1_5 from './components/L1/l5'
import l1_6 from './components/L1/l6'
import l1_7 from './components/L1/l7'

import l2_1 from './components/L2/l1'
import l2_2 from './components/L2/l2'
import l2_3 from './components/L2/l3'
import l2_4 from './components/L2/l4'
import l2_5 from './components/L2/l5'
import l2_6 from './components/L2/l6'
import l2_7 from './components/L2/l7'

export default {
  components: {
    l1_1,
    l1_2,
    l1_3,
    l1_4,
    l1_5,
    l1_6,
    l1_7,
    l2_1,
    l2_2,
    // l2_3,
    l2_4,
    l2_5,
    l2_6,
    l2_7
  },
  data() {
    return {
      currIndex: 0,
      dropDwons: {
        holiday: []
      },
      segements: ['日', '月', '年'],
      holidayList: [],
      formData: {
        holidayVal: null,
        dateVal: 1
      },
      currDateType: null
    }
  },
  mounted() {
    this.fetchHoliday()
  },
  methods: {
    async fetchHoliday() {
      const { resultCode, resultData } = await holiday()
      if (resultCode === '200' && resultData) {
        let list = [{ label: '节假日', value: 0 }]
        resultData.map((item, i) => {
          item.dateType = 'holiday'
          item.label = item.name
          item.value = i + 1
          list.push(item)
          this.holidayList.push(item)
        })
        this.dropDwons.holiday = list

        this.getDateType(this.formData.dateVal)
      }
    },
    handleTitle(i) {
      this.segements = i ? ['月', '年'] : ['日', '月', '年']
      this.currIndex = i
      this.dropDwons.holiday = []
      this.holidayList = []
      this.formData = {
        holidayVal: null,
        dateVal: i == 0 ? 1 : 0
      }
      this.currDateType = null
      this.fetchHoliday()
    },
    handleHoliday(value) {
      this.formData.holidayVal = value
      let holiday = this.holidayList[value - 1]
      this.getDateType(holiday)
    },
    handleDate(value) {
      this.formData.holidayVal = null
      this.formData.dateVal = value
      this.getDateType(value)
    },

    getDateType(val) {
      const { dateVal } = this.formData
      let dateObj = null
      if (val instanceof Object) {
        dateObj = val
      } else if (this.currIndex == 0) {
        dateObj = {
          dateType: dateVal == 0 ? 'day' : dateVal == 1 ? 'month' : 'year'
        }
      } else if (this.currIndex == 1) {
        dateObj = {
          dateType: dateVal == 0 ? 'month' : 'year'
        }
      }
      let coms = ['1', '2', '3', '4', '5', '6', '7']
      if (this.currIndex == 0) {
        coms.map(i => {
          this.$eventBus.$emit(`do-L1-${i}`, dateObj)
        })
      } else {
        coms.map(i => {
          this.$eventBus.$emit(`do-L2-${i}`, dateObj)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  .do-panel {
    height: 100%;
  }
  .box {
    @include flex(column);
    justify-content: space-between;
    padding: 25px;
    height: calc(100% - 50px);
  }
  .item-box {
    @include flex();
    align-items: center;
    justify-content: space-between;
  }
  .tools-box {
    @include flex();
    justify-content: flex-end;
  }
  .item {
    @include flex();
    justify-content: space-between;
  }
  .column {
    @include flex(column);
  }
  .i-box {
    // background-color: red;
  }
}
</style>
